import Highcharts, { color } from 'highcharts'
import { Chart } from 'highcharts-vue'

import { REFRESH_MOTION_MS, SOFT_MAX_RAW_LEVEL } from '@/utils/constant.ts'
import { IColorsEnum } from '@/store/modules/theme'

export default {
  accessibility: {
    announceNewData: {
      enabled: true,
      minAnnounceInterval: REFRESH_MOTION_MS,
      announcementFormatter: (allSeries: any, newSeries: any, newPoint: any) => {
        if (newPoint) {
          const date = new Date(newPoint.x)
          return `New motion added at ${date} with value set to ${newPoint.y}`
        }
        return false
      }
    }
  },
  chart: {
    type: 'areaspline',
    animation: (Chart as any).svg,
    backgroundColor: 'transparent',
    marginRight: 10
  },
  credits: {
    enabled: false
  },
  exporting: {
    enabled: false
  },
  legend: {
    enabled: false
  },
  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 650
        },
        chartOptions: {
          series: [
            {
              name: 'Activity level',
              marker: {
                radius: 3
              }
            }
          ],
          xAxis: {
            labels: {
              style: {
                fontSize: '10px'
              }
            }
          }
        }
      }
    ]
  },
  series: [
    {
      name: 'Activity level',
      data: [],
      fillColor: {
        linearGradient: [0, 0, 0, 400],
        stops: [
          [0, IColorsEnum.Blue],
          [
            1,
            color(IColorsEnum.Blue)
              .setOpacity(0.1)
              .get('rgba')
          ]
        ]
      },
      marker: {
        enabled: false
      }
    }
  ],
  title: null,
  tooltip: {
    formatter: function(): string {
      const { x, y, z, series } = (this as any).point
      const { name } = series

      return `
        <b>${name}:</b> ${y}<br/>
        <b>Activity type:</b> ${z}<br/>
        ${Highcharts.dateFormat('%H:%M:%S', x)}
      `
    }
  },
  xAxis: {
    type: 'datetime',
    lineColor: 'transparent',
    tickColor: 'transparent',
    labels: {
      style: {
        color: 'white',
        fontSize: '12px'
      }
    },
    dateTimeLabelFormats: {
      millisecond: '%H:%M:%S'
    },
    tickPixelInterval: 200
  },
  yAxis: {
    min: 0,
    softMax: SOFT_MAX_RAW_LEVEL,
    visible: false
  }
}
