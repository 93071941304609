

























import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'

import { IStateThing, ISerial } from '@/store/modules/thing'
import { REFRESH_THING_STATE_MS } from '@/utils/constant'
import { ThingStatusTypeEnum } from '@/utils/thingStatus'

export default Vue.extend({
  data() {
    return {
      timeout: 0,
      ThingStatusTypeEnum
    }
  },
  methods: {
    getCompositeStatus() {
      const hasSerial = this.$store.state.thing.serial !== null
      if (!hasSerial) return

      this.$store.dispatch('composite/getCompositeStatus').then(() => {
        this.timeout = setTimeout(this.getCompositeStatus, REFRESH_THING_STATE_MS)
      })
    }
  },
  computed: {
    ...mapState<IStateThing>('thing', {
      serial: (state: IStateThing) => state.serial
    }),
    ...mapGetters('composite', ['compositesStatus'])
  },
  watch: {
    serial: function() {
      clearTimeout(this.timeout)
      this.$store.commit('thing/resetThing')
      this.getCompositeStatus()
    }
  },
  mounted() {
    clearTimeout(this.timeout)
    this.getCompositeStatus()
  },
  beforeDestroy() {
    clearTimeout(this.timeout)
  }
})
