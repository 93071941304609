














import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'

import MotionChart from '@/components/Card/Live/Chart.vue'
import MotionStatus from '@/components/Card/Live/Status.vue'
import ThingStatus from '@/components/Notif/Thing.vue'
import { IStateActivity } from '@/store/modules/activity'
import { IStateThing } from '@/store/modules/thing'
import { REFRESH_MOTION_MS, FADE_TRANSITION } from '@/utils/constant'

export default Vue.extend({
  data() {
    return {
      loading: true,
      timeout: 0,
      transitionName: FADE_TRANSITION
    }
  },
  components: {
    MotionChart,
    MotionStatus,
    ThingStatus
  },
  methods: {
    getStatus() {
      const hasSerial = this.$store.state.thing.serial !== null
      if (!hasSerial) return clearTimeout(this.timeout)

      this.$store
        .dispatch('activity/getMotionStatus')
        .then(() => {
          this.timeout = setTimeout(this.getStatus, REFRESH_MOTION_MS)
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  computed: {
    ...mapState<IStateThing>('thing', {
      serial: (state: IStateThing) => state.serial
    }),
    ...mapGetters('composite', ['compositesConnected'])
  },
  watch: {
    serial: function() {
      clearTimeout(this.timeout)
      this.$store.commit('activity/resetMotionStatus')
      this.getStatus()
    }
  },
  mounted() {
    clearTimeout(this.timeout)
    this.getStatus()
  },
  beforeDestroy() {
    clearTimeout(this.timeout)
    this.$store.commit('activity/resetMotionStatus')
  }
})
