var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.compositesStatus.type !== _vm.ThingStatusTypeEnum.Valid
    ? _c(
        "article",
        { staticClass: "thing-status" },
        [
          _vm.compositesStatus.type === _vm.ThingStatusTypeEnum.Error
            ? _c(
                "router-link",
                { staticClass: "container", attrs: { to: "/setup" } },
                [
                  _c("font-awesome-icon", {
                    staticClass: "icon icon--error",
                    attrs: { icon: ["fas", "exclamation-circle"] }
                  }),
                  _c("span", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("" + _vm.compositesStatus.notif)) +
                        " "
                    )
                  ]),
                  _c("font-awesome-icon", {
                    staticClass: "icon icon--arrow",
                    attrs: { icon: ["fas", "chevron-right"] }
                  })
                ],
                1
              )
            : _vm.compositesStatus.type === _vm.ThingStatusTypeEnum.Warning
            ? _c(
                "router-link",
                { staticClass: "container", attrs: { to: "/setup" } },
                [
                  _c("font-awesome-icon", {
                    staticClass: "icon icon--warning",
                    attrs: { icon: ["fas", "exclamation-circle"] }
                  }),
                  _c("span", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("" + _vm.compositesStatus.notif)) +
                        " "
                    )
                  ]),
                  _c("font-awesome-icon", {
                    staticClass: "icon icon--arrow",
                    attrs: { icon: ["fas", "chevron-right"] }
                  })
                ],
                1
              )
            : _vm.compositesStatus.type === _vm.ThingStatusTypeEnum.Info
            ? _c(
                "div",
                { staticClass: "container" },
                [
                  _c("font-awesome-icon", {
                    staticClass: "icon icon--info",
                    attrs: { icon: ["fas", "info-circle"] }
                  }),
                  _c("span", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("" + _vm.compositesStatus.notif)) +
                        " "
                    )
                  ])
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }