var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.serial
    ? _c(
        "article",
        { staticClass: "live" },
        [
          _c("MotionStatus", { staticClass: "container" }),
          _vm.compositesConnected
            ? _c(
                "div",
                { staticClass: "card" },
                [
                  _c("header", { staticClass: "container" }, [
                    _c("h1", [_vm._v(_vm._s(_vm.$t("Card.Live.Title")))]),
                    _vm.loading
                      ? _c("p", [_vm._v(_vm._s(_vm.$t("Default.Loading")))])
                      : _vm._e()
                  ]),
                  _c("MotionChart")
                ],
                1
              )
            : _vm._e(),
          _c("ThingStatus", { staticClass: "container" })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }