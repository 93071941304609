var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.compositesConnected === null
    ? _c("article", { staticClass: "status" }, [
        _c(
          "div",
          { staticClass: "anim" },
          [
            _c("font-awesome-icon", {
              staticClass: "icon info",
              attrs: { icon: ["fas", "info-circle"] }
            })
          ],
          1
        ),
        _c("div", { staticClass: "info" }, [
          _c("p", { staticClass: "title" }, [
            _vm._v(" " + _vm._s(_vm.$t("Card.Live.Loading")) + " ")
          ])
        ])
      ])
    : !_vm.compositesConnected
    ? _c("article", { staticClass: "status" }, [
        _c(
          "div",
          { staticClass: "anim" },
          [
            _c("font-awesome-icon", {
              staticClass: "icon info",
              attrs: { icon: ["fas", "info-circle"] }
            })
          ],
          1
        ),
        _c("div", { staticClass: "info" }, [
          _c("p", { staticClass: "title" }, [
            _vm._v(" " + _vm._s(_vm.$t("Card.Live.NotConnected")) + " ")
          ])
        ])
      ])
    : _vm.current.humanMotion === null && _vm.current.motion === null
    ? _c("article", { staticClass: "status" }, [
        _c(
          "div",
          { staticClass: "anim" },
          [
            _c("font-awesome-icon", {
              staticClass: "icon info",
              attrs: { icon: ["fas", "info-circle"] }
            })
          ],
          1
        ),
        _c("div", { staticClass: "info" }, [
          _c("p", { staticClass: "title" }, [
            _vm._v(" " + _vm._s(_vm.$t("Card.Live.WaitingMotion")) + " ")
          ])
        ])
      ])
    : _vm.motionStatus
    ? _c("article", { staticClass: "status" }, [
        _c(
          "div",
          { staticClass: "anim" },
          [
            _vm.current.humanMotion
              ? _c("font-awesome-icon", {
                  staticClass: "icon human-motion",
                  attrs: { icon: ["fas", "walking"] }
                })
              : _vm.current.motion
              ? _c("font-awesome-icon", {
                  staticClass: "icon motion",
                  attrs: { icon: ["fas", "walking"] }
                })
              : _c("font-awesome-icon", {
                  staticClass: "icon",
                  attrs: { icon: ["fas", "male"] }
                })
          ],
          1
        ),
        _c("div", { staticClass: "info" }, [
          _vm.current.humanMotion
            ? _c("p", { staticClass: "title human-motion" }, [
                _vm._v(" " + _vm._s(_vm.$t("Card.Live.HumanMotion")) + " ")
              ])
            : _vm.current.motion
            ? _c("p", { staticClass: "title motion" }, [
                _vm._v(" " + _vm._s(_vm.$t("Card.Live.Motion")) + " ")
              ])
            : _c("p", { staticClass: "title" }, [
                _vm._v(_vm._s(_vm.$t("Card.Live.NoMotion")))
              ]),
          _c("p", [_vm._v(_vm._s(_vm.date) + " - " + _vm._s(_vm.duration))])
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }