







import Vue from 'vue'

import Live from '@/components/Card/Live/Index.vue'
import SelectThing from '@/components/Select/Thing.vue'

export default Vue.extend({
  components: {
    Live,
    SelectThing
  }
})
